<template>
  <div id="mian">
    <div class="header">
      <span id="fzcolor">基本信息</span>
      <span @click="toUrl('addcommercial_jsxx')">结算信息</span>
      <span @click="toUrl('addcommercial_qdxx')">渠道信息</span>
      <span @click="toUrl('addcommercial_pzxx')">产品信息</span>
      <!-- <span @click="toUrl('addcommercial_dkxq')">终端信息</span> -->
    </div>
    <div class="desc">
      <h5 class="jsxx">基本信息</h5>
    </div>
    <div class="input_con">
      <ul class="inputBox">
        <li>
          <span> <i>*</i>商户名称 </span>
          <el-input 
            class="inputs"
            v-model="formData.mchName"
            maxlength="128"
            placeholder="输入商户名称"
            tabindex="1"
          ></el-input>
        </li>
        <li>
          <span> <i>*</i>商户简称 </span>
          <el-input
            class="inputs"
            v-model="formData.mchShortName"
            maxlength="128"
            placeholder="输入商户简称"
            tabindex="2"
          ></el-input>
        </li>
        <li>
          <span>所属代理商</span>
          <el-select
            class="inputs"
            v-model="formData.agentId"
            placeholder="选择所属代理商"
            tabindex="3"
          >
            <el-option
              v-for="(v, i) in agentsLists"
              :key="i"
              :label="v.agentName"
              :value="v.agentId"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span> <i>*</i>商户类型 </span>
          <el-select
            class="inputs"
            v-model="formData.mchType"
            placeholder="选择商户类型"
            tabindex="4"
          >
            <el-option
              v-for="(v, i) in mchType"
              :key="i"
              :label="v.title"
              :value="v.value"
            ></el-option>
          </el-select>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span> <i>*</i>商户证件类型 </span>
          <el-select
            class="inputs"
            v-model="formData.licenceType"
            placeholder="选择商户证件类型"
            tabindex="5"
          >
            <el-option
              v-for="(v, i) in licenceType"
              :key="i"
              :label="v"
              :value="i + 1"
            ></el-option>
          </el-select>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span> <i>*</i>商户证件编号 </span>
          <el-input
            class="inputs"
            v-model="formData.licenceNo"
            maxlength="128"
            placeholder="输入商户证件编号"
            tabindex="6"
          ></el-input>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span> <i>*</i>联系人 </span>
          <el-input
            class="inputs"
            v-model="formData.contactName"
            maxlength="128"
            placeholder="输入联系人"
            tabindex="7"
          ></el-input>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span> <i>*</i>手机号码 </span>
          <el-input
            class="inputs"
            v-model="formData.contactTel"
            maxlength="11"
            placeholder="输入手机号码"
            tabindex="8"
          ></el-input>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span> <i>*</i>邮箱 </span>
          <el-input
            class="inputs"
            v-model="formData.contactEmail"
            maxlength="128"
            placeholder="输入邮箱"
            tabindex="9"
          ></el-input>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span> <i>*</i>客服电话 </span>
          <el-input
            class="inputs"
            v-model="formData.servicePhone"
            maxlength="128"
            placeholder="输入客服电话"
            tabindex="10"
          ></el-input>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span>网址</span>
          <el-input
            class="inputs"
            v-model="formData.mchSite"
            maxlength="128"
            placeholder="输入网址"
            tabindex="11"
          ></el-input>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span> <i>*</i>联系地址 </span>
          <el-input
            class="inputs"
            v-model="formData.contactAddress"
            maxlength="128"
            placeholder="输入联系地址"
            tabindex="12"
          ></el-input>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span> <i>*</i>法人姓名 </span>
          <el-input
            class="inputs"
            v-model="formData.legalPersonName"
            maxlength="128"
            placeholder="输入法人姓名"
            tabindex="13"
          ></el-input>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span> <i>*</i>法人身份证号码 </span>
          <el-input
            class="inputs"
            v-model="formData.idcardNo"
            maxlength="128"
            placeholder="输入法人身份证号码"
            tabindex="14"
          ></el-input>
        </li>
        <li v-if="formData.mchType !== 'MICRO_ENTERPRISE'">
          <span>备注</span>
          <el-input
            class="inputs"
            v-model="formData.remark"
            maxlength="128"
            placeholder="备注"
            tabindex="15"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span> <i>*</i>店铺地址 </span>
          <el-input
            class="inputs"
            v-model="formData.storeAddress"
            maxlength="128"
            placeholder="输入店铺地址"
            tabindex="6"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span> <i>*</i>经营类型 </span>
          <el-select
            class="inputs"
            v-model="formData.microType"
            placeholder="选择经营类型"
            tabindex="5"
          >
            <el-option
              v-for="(v, i) in microType"
              :key="i"
              :label="v.title"
              :value="v.value"
            ></el-option>
          </el-select>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span> <i>*</i>联系人 </span>
          <el-input
            class="inputs"
            v-model="formData.contactName"
            maxlength="128"
            placeholder="输入联系人"
            tabindex="8"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span> <i>*</i>店铺名称 </span>
          <el-input
            class="inputs"
            v-model="formData.storeName"
            maxlength="128"
            placeholder="输入店铺名称"
            tabindex="7"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span> <i>*</i>邮箱 </span>
          <el-input
            class="inputs"
            v-model="formData.contactEmail"
            maxlength="128"
            placeholder="输入邮箱"
            tabindex="10"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span> <i>*</i>手机号码 </span>
          <el-input
            class="inputs"
            v-model="formData.contactTel"
            maxlength="11"
            placeholder="输入手机号码"
            tabindex="9"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span> <i>*</i>联系地址 </span>
          <el-input
            class="inputs"
            v-model="formData.contactAddress"
            maxlength="128"
            placeholder="输入联系地址"
            tabindex="12"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span> <i>*</i>客服电话 </span>
          <el-input
            class="inputs"
            v-model="formData.servicePhone"
            maxlength="128"
            placeholder="输入客服电话"
            tabindex="11"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span>备注</span>
          <el-input
            class="inputs"
            v-model="formData.remark"
            maxlength="128"
            placeholder="备注"
            tabindex="14"
          ></el-input>
        </li>
        <li v-if="formData.mchType == 'MICRO_ENTERPRISE'">
          <span> <i>*</i>联系人身份证号码 </span>
          <el-input
            class="inputs"
            v-model="formData.idcardNo"
            maxlength="128"
            placeholder="输入联系人身份证号码"
            tabindex="13"
          ></el-input>
        </li>
      </ul>
    </div>
    <div class="upload_ph" v-show="formData.mchType !== 'MICRO_ENTERPRISE'">
      <div class="uploader1">
        <h4><i>*</i>商户证件</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="licencePictureSuccess"
        >
          <img
            v-if="formData.licencePicture"
            :src="formData.licencePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.licencePicture" class="avatar-uploader-span" @click.stop="formData.licencePicture = ''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证正面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardNormalPictureSuccess"
        >
          <img
            v-if="formData.idcardNormalPicture"
            :src="formData.idcardNormalPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardNormalPicture" class="avatar-uploader-span" data-val="idcardNormalPicture" @click.stop="formData.idcardNormalPicture=''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>法人身份证反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardReversePictureSuccess"
        >
          <img
            v-if="formData.idcardReversePicture"
            :src="formData.idcardReversePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardReversePicture" class="avatar-uploader-span"  @click.stop="formData.idcardReversePicture=''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>开户许可证</h4>
        <el-upload @click.stop
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="accountOpeningLicenseSuccess"
        >
          <img
            v-if="formData.accountOpeningLicense"
            :src="formData.accountOpeningLicense"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.accountOpeningLicense" class="avatar-uploader-span" @click.stop="formData.accountOpeningLicense=''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
    </div>
    <div class="upload_ph" v-show="formData.mchType == 'MICRO_ENTERPRISE'">
      <div class="uploader1">
        <h4><i>*</i>联系人身份证正面照片</h4>
        <el-upload @click.stop
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardNormalPictureSuccess"
        >
          <img
            v-if="formData.idcardNormalPicture"
            :src="formData.idcardNormalPicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardNormalPicture" class="avatar-uploader-span"  @click.stop="formData.idcardNormalPicture=''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>联系人身份证反面照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="idcardReversePictureSuccess"
        >
          <img
            v-if="formData.idcardReversePicture"
            :src="formData.idcardReversePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.idcardReversePicture" class="avatar-uploader-span" @click.stop="formData.idcardReversePicture=''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
      <div class="uploader1">
        <h4><i>*</i>店铺照片</h4>
        <el-upload
          class="avatar-uploader"
          :before-upload="beforeUpload"
          drag
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="storePictureSuccess"
        >
          <img
            v-if="formData.storePicture"
            :src="formData.storePicture"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <span v-if="formData.storePicture" class="avatar-uploader-span" @click.stop="formData.storePicture=''"><i class="el-icon-close"></i></span>
        </el-upload>
      </div>
    </div>
    <div class="upload_wj clear">
      <div class="upload_wj_left">
        <h5>商户协议</h5>
        <el-upload
          class="upload-demo"
          :before-upload="beforeUpload"
          :action="uploadUrl"
          :on-preview="handlePreview"
          :on-success="mchProtocolSuccess"
          :on-remove="mchProtocolRemove"
          multiple
          :file-list="mchProtocolList"
        >
          <el-button size="small" type="primary" class="djsc"
            >点击上传</el-button
          >
        </el-upload>
      </div>
      <div
        class="upload_wj_right"
        v-show="formData.mchType !== 'MICRO_ENTERPRISE'"
      >
        <h5>其他材料</h5>
        <!-- :before-upload="beforeUpload" -->
        <el-upload
          class="upload-demo"
          :action="uploadUrl"
          :on-preview="handlePreview"
          :on-success="otherPictureSuccess"
          :on-remove="otherPictureRemove"
          multiple
          :file-list="otherPictureList"
        >
          <el-button size="small" type="primary" class="djsc"
            >点击上传</el-button
          >
        </el-upload>
      </div>
    </div>
    <div class="bottom">
      <el-button
        @click="toUrl('addcommercial_jsxx')"
        class="next"
        type="primary"
        >下一步</el-button
      >
    </div>
    <div class="cover_view" v-if="showImg !== ''" @click="showImg = ''">
      <a :href="showImg" target="_blank">
        <img :src="showImg" alt="加载失败" />
      </a>
    </div>
  </div>
</template>
<script>
import { URL_API } from "@/utils/apiUrl.js";
import { agentsDrop } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
import * as imageConversion from "image-conversion";
export default {
  data() {
    return {
      isImge: true, //判断商户协议上传的是不是图片
      formData: {
        mchName: "", //商户名称
        mchShortName: "", //商户简称
        agentId: "", //代理商ID false
        mchType: "", //商户类型，
        licenceType: null, //商户证件类型
        licenceNo: "", //商户证件编号
        contactName: "", //联系人
        contactTel: "", //联系电话
        contactEmail: "", //邮箱
        contactAddress: "", //联系地址
        legalPersonName: "", //法人姓名
        idcardNo: "", //身份证号码（法人或联系人）
        remark: "", //备注 false
        licencePicture: "", //商户证件照片URL
        servicePhone: "", //客服电话
        mchSite: "", //网址 false
        accountOpeningLicense: "", //开户许可证图片URL true
        mchProtocol: "", //商户协议
        otherPicture: "", //其他材料链接URL false
        microType: null, // false小微经营类型，
        storeName: "", //false	店铺名称，商户类型为小微商户时必填
        storeAddress: "", //	false	店铺地址，商户类型为小微商户时必填
        storePicture: "", //	false	店铺照片，商户类型为小微商户时必填
        idcardNormalPicture: "", //	true	身份证正面照片（法人或联系人）
        idcardReversePicture: "", //	true	身份证反面照片（法人或联系人
      },
      agentsLists: [], //代理商列表
      licenceType: [
        "统一社会信用代码证书",
        "有偿服务许可证（军队医院适用）",
        "医疗机构执业许可证（军队医院适用）",
        "企业营业执照（挂靠企业的党组织适用）",
        "组织机构代码证（政府机关适用）",
        "社会团体法人登记证书",
        "民办非企业单位登记证书",
        "基金会法人登记证书",
        "慈善组织公开募捐资格证书",
        "农民专业合作社法人营业执照",
        "宗教活动场所登记证",
        "营业执照",
        "营业执照号码（多合一）",
        "事业单位法人证书",
        "其他证书/批文/证明",
      ],
      microType: [
        {
          title: "店铺场所",
          value: "MICRO_TYPE_STORE",
        },
        {
          title: "流动经营/便民服务",
          value: "MICRO_TYPE_MOBILE",
        },
        {
          title: "线上商品/服务交易",
          value: "MICRO_TYPE_ONLINE",
        },
      ],
      mchType: [
        {
          title: "企业",
          value: "ENTERPRISE",
        },
        {
          title: "个体工商户",
          value: "INDIVIDUAL_BUSINESS",
        },
        {
          title: "小微商户",
          value: "MICRO_ENTERPRISE",
        },
        {
          title: "事业单位",
          value: "PUBLIC_INSTITUTION",
        },
        {
          title: "民办非企业单位",
          value: "PRIVATE_NONENTERPRISE_UNIT",
        },
        {
          title: "社会团体",
          value: "SOCIAL_ORGANIZATION",
        },
      ],
      uploadUrl: URL_API.upload,
      mchProtocolList: [],
      otherPictureList: [],
      showImg: "",
    };
  },
  computed: {
    ...mapState({
      businessDetail: "user_businessDetail",
    }),
  },
  mounted() {
    this.getAgentsLists();
    if (this.businessDetail) {
      this.formData = this.businessDetail.mchDetail;
      this.mchProtocolList = this.formData.mchProtocol
        ? JSON.parse(this.formData.mchProtocol)
        : [];
      this.otherPictureList = this.formData.otherPicture
        ? JSON.parse(this.formData.otherPicture)
        : [];
    }
    // if (sessionStorage.getItem('mchDetail')) {
    // 	this.formData = JSON.parse(sessionStorage.getItem('mchDetail'))
    // 	this.mchProtocolList = JSON.parse(this.formData.mchProtocol)
    // 	this.otherPictureList = JSON.parse(this.formData.otherPicture)
    // }
  },
  methods: {
    ...mapMutations({
      setBusiness: "user_setBusiness",
    }),
    // 获取代理商下拉
    getAgentsLists() {
      agentsDrop().then((res) => {
        this.agentsLists = res.resultData;
      });
    },
    // 商户证件上传
    licencePictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.licencePicture = res.resultData.fileLink;
        console.log("商户证件上传成功", res);
      } else {
        console.log("商户证件上传失败", res);

        return false;
      }
    },
    // 身份证正面照片（法人或联系人）上传
    idcardNormalPictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.idcardNormalPicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 身份证反面照片（法人或联系人）上传
    idcardReversePictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.idcardReversePicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 开户许可证上传
    accountOpeningLicenseSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.accountOpeningLicense = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 店铺照片上传
    storePictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storePicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 商户协议上传
    mchProtocolSuccess(res) {
      let testmsg = res.resultData.fileName.substring(res.resultData.fileName.lastIndexOf(".") + 1);
      if (testmsg != "jpg" && testmsg != "JPG" && testmsg != "png" && testmsg != "PNG" && testmsg != "bpm" && testmsg != "BPM") {
        this.isImge = false;
        return false;
      }
      if (this.isImge && res.resultStatus) {
        let data = {
          name: res.resultData.fileName,
          url: res.resultData.fileLink,
        };
        this.mchProtocolList.push(data);
      } else {
        return false;
      }
    },
    // 商户协议移除
    mchProtocolRemove(res, files) {
      this.mchProtocolList = [];
      files.forEach((v) => {
        let data = {
          name: v.name,
          url: v.url,
        };
        this.mchProtocolList.push(data);
      });
    },
    // 其他材料上传
    otherPictureSuccess(res) {
      if (res.resultStatus) {
        let data = {
          name: res.resultData.fileName,
          url: res.resultData.fileLink,
        };
        this.otherPictureList.push(data);
      }
    },
    // 其他材料移除
    otherPictureRemove(res, files) {
      this.otherPictureList = [];
      files.forEach((v) => {
        let data = {
          name: v.name,
          url: v.url,
        };
        this.otherPictureList.push(data);
      });
    },
    // 表单规则
    roles() {
      let formData = JSON.parse(JSON.stringify(this.formData));
      if (!formData.mchName) {
        this.$message.error("商户名称必填");
        return false;
      }
      if (!formData.mchShortName) {
        this.$message.error("商户简称必填");
        return false;
      }
      if (!formData.mchType) {
        this.$message.error("商户类型必选");
        return false;
      }
      if (formData.mchType == "MICRO_ENTERPRISE") {
        if (!formData.microType) {
          this.$message.error("经营类型必选");
          return false;
        }
        if (!formData.storeAddress) {
          this.$message.error("店铺地址必填");
          return false;
        }
        if (!formData.storeName) {
          this.$message.error("店铺名称必填");
          return false;
        }
        if (!formData.idcardNo) {
          this.$message.error("联系人身份证号码必填");
          return false;
        } else {
          if (
            !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
              formData.idcardNo
            )
          ) {
            this.$message.error("请输入合法的身份证号码");
            return false;
          }
        }
        if (!formData.idcardReversePicture) {
          this.$message.error("联系人身份证反面照片必传");
          return false;
        }
        if (!formData.idcardNormalPicture) {
          this.$message.error("联系人身份证正面照片必传");
          return false;
        }
        if (!formData.storePicture) {
          this.$message.error("店铺照片必传");
          return false;
        }
      } else {
        if (!formData.licenceType) {
          this.$message.error("商户证件类型必选");
          return false;
        }
        if (!formData.licenceNo) {
          this.$message.error("商户证件编号必填");
          return false;
        }
        if (!formData.legalPersonName) {
          this.$message.error("法人姓名必填");
          return false;
        }
        if (!formData.idcardNo) {
          this.$message.error("法人身份证号码必填");
          return false;
        } else {
          if (
            !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
              formData.idcardNo
            )
          ) {
            this.$message.error("请输入合法的身份证号码");
            return false;
          }
        }
        if (!formData.licencePicture) {
          this.$message.error("商户证件照片必传");
          return false;
        }
        if (!formData.idcardReversePicture) {
          this.$message.error("法人身份证反面照片必传");
          return false;
        }
        if (!formData.idcardNormalPicture) {
          this.$message.error("法人身份证正面照片必传");
          return false;
        }
        if (!formData.accountOpeningLicense) {
          this.$message.error("开户许可证必传");
          return false;
        }
      }
      if (!formData.contactName) {
        this.$message.error("联系人必填");
        return false;
      }
      if (!formData.contactTel) {
        this.$message.error("手机号码必填");
        return false;
      } else {
        if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(formData.contactTel)) {
          this.$message.error("请正确填写手机号码");
          return;
        }
      }

      if (!formData.contactEmail) {
        this.$message.error("邮箱必填");
        return false;
      } else {
        if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            formData.contactEmail
          )
        ) {
          this.$message.error("邮箱格式不合法");
          return;
        }
      }
      if (!formData.servicePhone) {
        this.$message.error("客服电话必填");
        return false;
      }
      if (!/^[0-9\-]+$/.test(formData.servicePhone)) {
        this.$message.error("请正确填写客服电话");
        return;
      }
      if (!formData.contactAddress) {
        this.$message.error("联系地址必填");
        return false;
      }

      if(JSON.stringify(this.mchProtocolList).length > 1536 || JSON.stringify(this.otherPictureList).length > 1536){
        this.$message.error("超出上传上限,请先移除已上传文件");
        return false;
      }

      if (this.mchProtocolList.length > 0) {
        formData.mchProtocol = JSON.stringify(this.mchProtocolList);
      }
      if (this.otherPictureList.length > 0) {
        formData.otherPicture = JSON.stringify(this.otherPictureList);
      }
      // sessionStorage.setItem('mchDetail', JSON.stringify(formData))
      return true;
    },
    // 页面跳转
    toUrl(name) {
      if (!this.roles()) {
        return;
      }
      this.$router.push({
        name: name,
      });
    },
    // 点击展示图片
    handlePreview(e) {
      this.showImg = e.url;
    },
    beforeUpload(file) {
      this.isImge = true;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      if (!extension) {
        this.$message.error("上传图片仅支持jpg/jpeg/png/bpm格式");
        this.isImge = false;
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    },
  },
  beforeDestroy() {
    let formData = JSON.parse(JSON.stringify(this.formData));
    if (this.mchProtocolList.length > 0) {
      formData.mchProtocol = JSON.stringify(this.mchProtocolList);
    }
    if (this.otherPictureList.length > 0) {
      formData.otherPicture = JSON.stringify(this.otherPictureList);
    }
    let data = {
      mchDetail: formData,
      settlements: this.businessDetail ? this.businessDetail.settlements : null,
      mchChannels: this.businessDetail ? this.businessDetail.mchChannels : null,
    };
    this.setBusiness(data);
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
@import "../../../assets/css/editInput.css";

.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  font-size: 12px;
  padding: 0;
}

.bottom {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 18px;
}


.djsc {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
}

.upload_wj_right {
  float: left;
  margin-left: 200px;
  width: 22%;
  height: 100%;
}

.upload_wj h5 {
  padding: 29px 0 19px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.upload_wj_left {
  float: left;
  margin-left: 44px;
  width: 22%;
  height: 100%;
}

.upload_wj {
  margin-bottom: 25px;
  width: 100%;
  padding-bottom: 25px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
}

.uploader1 {
  margin-left: 30px;
  float: left;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 i {
  color: #48b8b6;
  padding-right: 3px;
}

.uploader2 {
  margin-left: 30px;
  margin-right: 19px;
  float: left;
}

.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload_ph p span i {
  color: #48b8b6;
  padding-right: 3px;
}

.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.upload_ph {
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-bottom: 20px;
}


.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}

.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 90% ;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.upload_wj_left i {
  color: #48b8b6;
  padding-right: 3px;
}
</style>
